<template>
  <div class="container">
    <div class="container_self">
      <div class="header-content">
        <div class="left">
          <!-- <router-link to="/PublishHistoryList"> <i class="el-icon-back left-icon"></i></router-link> -->
          <i class="el-icon-back left-icon"  @click="goBackHistoryList"></i>
          <span class="left-title">History Detail</span>
        </div>
        <div class="right">
          <el-button type="primary" icon="el-icon-view" @click="showPreviewDialog" class="preview-btn">Preview</el-button>
          <el-button type="primary" icon="el-icon-folder-opened" @click="showSaveChanages" class="save-btn">Save Chanages</el-button>
        </div>
      </div>
      <!-- 基础数据部分 -->
      <div class="baseWrapper">
        <div class="base-sku"> SKU: <span>{{mainData.skuNumber}}</span></div>
        <div class="base-item">
            <span>Platform</span><br><br>
            <el-select v-model="mainData.platform" placeholder="Platform" value-key="value" filterable clearable @change="chanagePlatform">
              <el-option
                v-for="(item,index) in platformList"
                :key="item.value"
                :label="item.title"
                :value="item.value"
                :index="index">
              </el-option>
            </el-select>
          </div>
          <div class="base-item">
            <span>Site</span><br><br>
            <el-select v-model="mainData.site" placeholder="Site" @change="chanageSite"  filterable clearable>
                  <el-option
                    v-for="item in siteList"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
          </div>
          <div class="base-item">
            <span>Brand</span><br><br>
            <el-input placeholder="Brand" v-model="mainData.brandName" @change="chanageBrand" ></el-input>
          </div>
      </div>
      
      <div class="product-category">
        
        <div class="cate-title">
          <h2 class="sub-title">Category</h2>
        </div>
        <div class="cate-main">
          <!-- 分类选择 -->
          <div class="cate-tag">
            <el-tag
              v-for="(item, index) in mainData.productListingList"
              :key="index"
              :class="{ active: productListingIndex === index }"
              closable
              @click="setActiveIndex(index)"
              @close="removeCategory(index)" 
            >
              <span>{{ item.categoryPath }}</span>
            </el-tag>
          </div>
          <div class="category-selector">
            <el-input
              v-model="newCategory"
              placeholder="Add a category*(e.g., Furniture> Office>Chairs)"
            ></el-input>
            <!-- 操作按钮组 -->
            <div class="action-group">
              <el-button type="primary" icon="el-icon-plus" class="action-add-button" @click="addCategory" :loading="addCategoryLoading" :disabled="idaddCategoryBtnShow">Add</el-button>
              <el-button type="primary" icon="el-icon-magic-stick" class="action-amsrt-btn" @click="toggleSmartMatch" :loading="toggleSmartMatchLoading">Smart Match</el-button>
            </div>
          </div>

          <el-collapse-transition>
            <div v-if="showSmartMatch" class="smart-match-info">
              <div style="position: absolute;right: 10px;top: 2px; z-index:5; padding: 5px;cursor: pointer;"><i class="el-icon-close" @click="closeSmartMatch"></i></div>
              <div style="float: left;width: 95%;margin: 0px auto;text-align: left;">
                  <div style="font-size: 14px; font-weight: 550;">Precise Categories </div>
                  <el-checkbox-group v-model="checkedCateList" class="cate-check" @change="changeFurnitureCate">
                    <el-checkbox v-for="(item,index) in furnitureCategories" :label="item" :key="index"><span class="checkbox-label" @click.stop>{{ item }}</span></el-checkbox>
                  </el-checkbox-group>
              </div>
              <!-- <div class="float: right;width: 45%;text-align: left;">
                  <div style="font-size: 14px; font-weight: 550;">Creative Categories</div>
                  <el-checkbox-group v-model="checkedCateListNoFurniture" class="cate-check" @change="changeNoFurnitureCate">
                    <el-checkbox v-for="(item,index) in nofurnitureCategories" :label="item" :key="index"><span class="checkbox-label" @click.stop>{{ item }}</span></el-checkbox>
                  </el-checkbox-group>
              </div> -->
              <!-- <button @click="closeSmartMatch" style="position: absolute;right: 10px;bottom: 10px; z-index:5; padding: 5px;cursor: pointer;">Close</button> -->
            </div>
          </el-collapse-transition>

          
        </div>
      </div>

      <div class="convas-container">
        <div class="convas-title">
          <h2 class="sub-title-span">Product Images</h2>
          <el-button icon="el-icon-download"  class="convas-title-btn" :loading="downloadImageZipLoading" @click="downloadImageZip">Download</el-button>
        </div>
        <div class="convas-main">
          <div class="main-img">
            <el-image
              v-if="currentImageUrl"
              :src="currentImageUrl"
              fit="contain"
            ></el-image>
            <img  src="../assets/img404.jpg" v-else class="placeholder-image">
            <!-- <el-image
              v-else
              src="../assets/img404.jpg"
              fit="contain"
            ></el-image> -->
          </div>
          <div class="opre-img">
            <ul v-if="currentImageUrlList && currentImageUrlList.length > 0">
              <li v-for="(img, index) in currentImageUrlList" :key="index" class="image-item thumbnail-item" :class="{ 'selected': selectedImage === index }" @click="setSelectedImage(index)">
                <el-image
                  :src="img.jpegUrl"
                  fit="contain"
                >
                  <template #placeholder>
                    <i class="el-icon-loading" />
                  </template>
                  <template #error>
                    <i class="el-icon-error" />
                  </template>
                </el-image>
                <!-- 添加删除图标 -->
                <div class="delete-icon" @click.stop="removeImage(index)">
                  <i class="el-icon-delete"></i>
                </div>
                <!-- 添加编辑图标 -->
                <div class="edit-icon" @click.stop="decoraImgage(index)">
                  <i class="el-icon-edit-outline"></i>
                </div>
              </li>
            </ul>
            
            <div class="add-img-icon" @click="showEditlDialogImageVisible">
              <i class="el-icon-plus"></i>
            </div>
          </div>
        </div>
      </div>
      
      <div class="field-container">
        <!-- 左边Description-->
        <div class="field-content">
          <div class="field-content-title">
            <h2 class="field-content-title-span">Description & Title  <el-button @click="copyTitleDecrition">Copy</el-button></h2>
          </div>
          <div class="field-content-main">
            <div class="field-conten-item" v-if="mainData.productListingList && mainData.productListingList.length > 0 ">
              <div class ="ori-data">
                  <div>Title <br><el-input type="textarea" :rows="3" v-model="mainData && mainData.productListingList && mainData.productListingList[productListingIndex].title" ></el-input></div>
                  <div>Description <br><el-input type="textarea" :rows="6"  v-model="mainData && mainData.productListingList && mainData.productListingList[productListingIndex].description"></el-input></div>
                  <div v-if="mainData.productListingList[productListingIndex]">
                    Bullet Points<br>
                    <ul class="bullet-list">
                      <li v-for="(point, index2) in mainData.productListingList[productListingIndex].bulletPoints" :key="index2" class="bullet-list-li">
                        <el-input type="textarea" :rows="2" v-model="mainData && mainData.productListingList && mainData.productListingList[productListingIndex].bulletPoints[index2]" class="large-textarea"></el-input>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
            <div class="field-Keywords">
              <p>Listing Keywords</p><br>
              <el-input  v-model="keywords" placeholder="Enter keywords separated by commas (e.g., modern, bed frame, queen size)"></el-input>
            </div>
            <div class="field-Keywords">
              <p>
                Product Information Images   
                <el-switch
                  v-model="isAddInformationImages"
                  active-color="#111111"
                  inactive-color="#e4e4e4">
                </el-switch>
              </p>
              <div v-if="isAddInformationImages" class="image-upload-container">
                <div class="image-upload-num">{{productInfoImageUrlList.length}}/5 images</div>
                <div class="image-preview">
                  <div
                    v-for="(image, index) in productInfoImageUrlList"
                    :key="index"
                    class="image-item"
                  > 
                    <el-image 
                      :src="image" 
                      alt=""
                      :preview-src-list="[image]"
                      fit="contain"
                    />
                    <el-button
                      type="Info"
                      icon="el-icon-delete"
                      @click="removeAddInformationImage(index)"
                    ></el-button>
                  </div>
                  <el-upload
                      ref="imageAddInformationUpload"
                      list-type="picture-card"
                      multiple
                      :before-upload="beforeAddInformationUpload"
                      :on-change="handleAddInformationChange"
                      :show-file-list="false"
                      :disabled="productInfoImageUrlList.length >= 5"
                      action="" 
                    >
                      <i class="el-icon-plus"></i>
                    </el-upload>
                </div>
                <div class="image-upload-tips">Upload product information images to help the Al generate more relevant content for your product.</div>
              </div>
            </div>
            <div class="field-btn-wrapper">
              <el-button class="field-content-btn" :loading="fetchContentDetailsLoading" @click="fetchContentDetails">Al Generate Content</el-button>
            </div>
          </div>
          
        </div>
        <!-- 右边attr -->
        <div class="field-attr">
          <div class="field-attr-title">
            <h2 class="field-attr-span">Attributes</h2>
          </div>
          <div class="field-attr-main">
            <div class="infoma-right-top">
              
              <!-- <div style="display: inline-block;  width: 70%; float: left;"> -->
              <div>
                <p>Import</p>
                <el-input v-model="editskuNumber" style=" width: 72%;margin-top: 10px;margin-right: 10px;" placeholder="Please enter SKU/Good Name/Short Code"></el-input>
                <el-button class="enh-btn" @click="getExcelDataList()" :loading="getExcelDataListLoading">Auto-fill</el-button>
              </div>
              <!-- <div style="display: inline-block; width: 22%; text-align: left; float: right;">
                <p style="padding-left: 10px;">Size Drawing</p>
                <el-button class="enh-btn" icon="el-icon-upload2" @click="sizeDrawingVisible = true">Upload</el-button>
              </div> -->
            </div>
            <ul class="infoma-right-ul">
              <li class="infoma-right-ul-li">
                <div class="infoma-right-ul-li-no">No.</div>
                <div class="infoma-right-ul-li-name">Name.</div>
                <div class="infoma-right-ul-li-value">Value.</div>
              </li>
              <li v-for="(item,index) in mainData.excelDataList" :key="index" v-if="item.display" class="infoma-right-ul-li" >
                <div class="infoma-right-ul-li-no">{{ getVisibleCount(index) }}</div>
                <div class="infoma-right-ul-li-name">{{item.title}}:</div>
                <div class=""><el-input v-model="item.cellValue"></el-input></div>
              </li>
            </ul>
          </div>
          
        </div>
      </div>

    </div>
    <!-- 图片画布编辑 -->
    <el-dialog :visible.sync="editlDialogImageVisible" title="Add Product Image" top="3vh" :append-to-body="true" @open="initCanvasDialog" :before-close="handleEditlDialogImageClose">
      <div class="canvas-wrapper" >
        
        <div class="canvasMain">
          <div style="max-width: 600px; margin: 0px auto;">
            <canvas id="fabricCanvas" :width="canvasWidth" :height="canvasHeight"  class="canvasDiv"></canvas>
          </div>
          <div class="canvas-upload">
            <!-- 自定义样式的按钮 -->
            <div class="canvas-upload-title"><i class="el-icon-link"></i>&nbsp;Add Image</div>
            <div class="file-and-link">
              <el-input v-model="productLink" class="link-input" placeholder="https://" @change="chanageProductLinkDialog"></el-input>
              <span class="upload-span"> or </span>
              <!-- 隐藏的文件输入 -->
              <input type="file" class="uploadFile" @change="handleFileChangeDialog" ref="fileInput" />
              <el-button class="upload-button" @click="triggerFileInput" :loading="triggerFileInputLoading">Add</el-button>
            </div>
            
          </div>
        </div>
        <div class="canvasSizeWrapper">
          <div class="canvas-upload-title"><i class="el-icon-rank"></i>&nbsp;Image Dimensions</div>
          <div class="canvas-upload-top">
            <div class="canvas-chanage-size">
              <div class="canvas-chanage-size-item">
                <span>Width:&nbsp;</span>
                <el-input class="mini-input" placeholder="width" v-model.number="exportImgWidth" type="number" :min="100" :max="5000" @change="chanageExportImgSizeDialog"></el-input>
                <span>PX</span>
              </div>
              
              <div class="canvas-chanage-size-item">
                <span>Height:&nbsp;</span>
                <el-input class="mini-input" placeholder="height" v-model.number="exportImgHeight" type="number" :min="100" :max="5000" @change="chanageExportImgSizeDialog"></el-input>
                <span>PX</span>
              </div>
            </div>
            
            <div class="canvas-chanage-donload">
              <div class="canvas-chanage-donload-title"><i class="el-icon-download"></i>&nbsp;Download Matted Image</div>
              <el-button :disabled="downloadMattingImageDisabled" icon="el-icon-download" class="canvas-chanage-donload-btn" @click="downloadMattingImage">Download</el-button>
            </div>
          </div>
          <div class="convas-prompt">
            <div class="canvas-upload-title"><i class="el-icon-magic-stick"></i>&nbsp;Prompt Optimizer</div>
            <!-- <el-input type="textarea" :rows="3" v-model="guidance"></el-input> -->
            <textarea class="el-textarea-inner" v-model.lazy="guidance" rows="3" ></textarea>
            <div class="generate-btnWrapper">
              <el-button  class="generate-opt-btn" icon="el-icon-magic-stick" @click="generateImagePrompt" :loading="generateImagePromptLoading">Optimize Prompt</el-button>
            </div>
          </div>
          <div class="convas-prompt">
            <div class="canvas-upload-title"><i class="el-icon-connection"></i>&nbsp;Al Image Generation</div>
            <el-input type="textarea" :rows="3" v-model="prompt"></el-input>
            <div class="generate-btnWrapper">
              <el-button  class="generate-gen-btn" icon="el-icon-connection" @click="submitCanvasDialog" :loading="submitCanvasDialogLoading">Generate</el-button>
            </div>
          </div>
          
        </div>
      </div>
      
    </el-dialog>


    <!-- preview框 -->
    <el-dialog :visible.sync="dialogVisible" title="Preview Detail"  top="5vh" :append-to-body="true" :before-close="handlePreviewClose">
      <div class="preview-wrapper">
        <div class="preview-content" v-if="currentImageUrlList">
          <div class="preview-img">
            <div v-if="currentImageDialogUrl">
              <el-image
                :src="currentImageDialogUrl"
                class="preview-max-img"
                fit="contain"
              ></el-image>
            </div>
            <div v-else class="preview-placeholder"></div>
            <ul v-if="currentImageUrlList">
              <li v-for="(item ,index) in currentImageUrlList" :key="index" @click="changePreviewImg(index)"> 
                <el-image
                  :src="item.jpegUrl"
                  class="preview-mini-img"
                  fit="contain"
                ></el-image>
              </li>
            </ul>
          </div>
          <div class="preview-attr">
            <div class="preview-cate">{{mainData.productListingList[productListingIndex] && mainData.productListingList[productListingIndex].title}}</div>
            <div class="preview-rate">
              <el-rate
                v-model="rateValue"
                disabled
                show-score
                text-color="#ff9900"
                score-template="{value}">
              </el-rate>
              <span>(2,384 ratings)</span>
            </div>
            <div class="preview-price">
              <p>Price:</p>
              <p class="price-span">$299.99</p>
              <p>& FREE Returns</p>
            </div>
            <div class="preview-bullet">
              <p>About this item</p>
              <ul v-if="mainData.productListingList[productListingIndex]">
                <li v-for="(point, index2) in mainData.productListingList[productListingIndex].bulletPoints" :key="index2" >
                  {{mainData.productListingList && mainData.productListingList[productListingIndex].bulletPoints[index2]}}
                  
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="preview-desc">
          <p>About this item</p>
          <p class="preview-desc-span">
            {{mainData.productListingList[productListingIndex] && mainData.productListingList[productListingIndex].description}}
          </p>
        </div>
      </div>
    </el-dialog>

    <!-- 保存弹框 -->
    <el-dialog :visible.sync="savedialogVisible"  top="25vh" :append-to-body="true" class="savedialog-wrapper" :modal="true" :before-close="handleSaveChanagesClose">
        <div>
          <h2>Save Changes</h2>
          <p>Choose how you want to save your changes</p>
        </div>
        <div class="save-dialog-div" @click="saveChanages">
          <div style="width: 50px"><i class="el-icon-folder-opened" style="font-size: 25px; font-weight: blod;color: blue;margin-top:10px;"></i></div>
          <div style="flex: 1">
            <h2>Save and Overwrite</h2>
            <p>Update the existing record with your changes</p>
           </div>
        </div>
        <div class="save-dialog-div" @click="createChanages">
          <div style="width: 50px"><i class="el-icon-folder-add" style="font-size: 25px; font-weight: blod;color: #cda1f5;margin-top:10px;"></i></div>
          <div style="flex: 1">
            <h2>Save as New Record</h2>
            <p>Create a new record while keeping the original</p>
           </div>
        </div>
    </el-dialog>
    <!-- 图片加装饰框 -->
    <el-dialog :visible.sync="decoraImgDialogVisible" title="Add Item"  top="10vh" :append-to-body="true" style="width: 85% !important; margin:0px auto;">
      <div class="preview-wrapper">
        <div v-if="decoraImgUrl" class="decora-img">
          <el-image
            :src="decoraImgUrl"
            class="decora-max-img"
            fit="contain"
          ></el-image>
        </div>
        <div class="decora-opre">
          <el-input v-model="addImageItemValue" placeholder="Enter the item you want to add to the image and the desired effect, such as 'Add a cup of coffee on the table.'"></el-input>
          <el-button @click="addImageItem" :loading="addImageItemLoading">Add</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 图片size Drawing -->
    <el-dialog :visible.sync="sizeDrawingVisible" title="Furniture Dimension Recognition"  top="5vh" :append-to-body="true" style="width: 85% !important; margin:0px auto;">
      <div class="sizeDrawingWrapper">
        <div class="sizeDrawing-left">
          <h3>Upload Dimension Image</h3>
          <div class="upload-section">
            <div v-if="!sizeDrawingUrl" class="upload-img-main">
              <el-upload
                class="upload-demo"
                ref="imageUpload"
                drag
                action=""
                :limit="1"
                :on-change="handleFileChange"
                :before-upload="beforeUpload"
                :auto-upload="false"
                :show-file-list="false"
                accept="image/jpeg,image/png">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">Click or drag to upload</div>
                <div class="el-upload__tip" slot="tip">Supports JPG,PNG format images</div>
              </el-upload>
            </div>
            <div v-else class="upload-img-show">
              <img :src="sizeDrawingUrl" />
            </div>
          </div>
          <div class="sizeDrawing-left-input">
            <el-input placeholder="Enter image URL"></el-input>
          </div>
          <div class="sizeDrawing-left-btn">
            <el-button :disabled="!sizeDrawingUrl">Recognize Dimensions</el-button>
          </div>
          <div class="upload-tips">
            <h3>Upload Tips</h3>
            <ul>
              <li>Please upload clear product dimension images</li>
              <li>Ensure dimension numbers are clearly visible</li>
              <li>Image should include length, width, height measurements</li>
              <li>White background images provide better recognition accuracy</li>
            </ul>
          </div>
        </div>

        <div class="sizeDrawing-right">
          <h3>Recognition Results</h3>
          <div class="right-noresult">
            <div class="right-noresult-icon"><i class="el-icon-document-delete"></i></div>
            <div><h3>No Dimensions Recognized</h3></div>
            <div>Please upload a product dimension image and click
"Recognize Dimensions"</div>
          </div>
          <!-- <div class="right-result">
            <ul>
              <li>
                <span class="result-parameter">Parameter</span>
                <span class="result-value">Value</span>
                <span class="result-unit">Unit</span>
              </li>

            </ul>
          </div> -->
        </div>
      </div>
      <div  class="sizeDrawing-footer">
        <el-button @click="sizeDrawingVisible = false" class="canel-btn">Cancel</el-button>
        <el-button type="primary" @click="sizeDrawingVisible = false" class="apply-btn" disabled>Apply Dimensions</el-button>
      </div>
    </el-dialog>

    <!-- 页面加载效果 -->
    <div class="loadingMaskNoBg" v-if="pageloading">
      <div class="progress-container">
        <el-progress type="circle" :percentage="progress" :stroke-width="8"></el-progress>
      </div>
    </div>
    <!-- 页面加载效果 -->
  </div>
</template>
<script>
import * as fabric from 'fabric';
import { Canvas, FabricImage } from 'fabric';
import * as DownloadUtils from '@/utils/DownloadUtils.js';
import _ from 'lodash';
export default {
  name:"PublishHistoryEdit",
  
  data(){
    return{
      id: this.$route.query.id,
      query:{},
      mainData:{
        productListingList:[]
      },
      oriMainData:{
        productListingList:[]
      },
      multipleSelection:[],
      dataList:[],
      searchLoading: false,
      btnLoading: false,
      pageDomain: {},
      accountList: [],
      siteList: [],
      statusList: [],
      platformList:[
        {
          "title":"Walmart",
          "value": "Walmart",
          "showFlag": false,
          "selectSiteList":["US","CA"]
        },
        {
          "title":"Walmart_DSV",
          "value": "Walmart DSV",
          "showFlag": false,
          "selectSiteList":["US"]
        },
        {
          "title":"Amazon",
          "value": "Amazon",
          "showFlag": false,
          "selectSiteList":["US","CA","DE","FR","UK"]
        },
        {
          "title":"Amazon_Vendor",
          "value": "Amazon Vendor",
          "showFlag": false,
          "selectSiteList":["US","CA","DE","FR","UK"]
        },
        {
          "title":"Temu",
          "value": "Temu",
          "showFlag": false,
          "selectSiteList":["ES","IT","FR","DE"]
        }
      ],
      pageSelectCount: 0,
      filterByList:[
        {
          title:'Sku.',
          value:"skuNo",
        },
        {
          title:'Platform Sku ID.',
          value:"platformSkuId",
        },
        {
          title:'Platform Sku Name.',
          value:"platformSkuName",
        }
      ],
      dialogVisible: false,
      selectedRow: null, // 存储选中的行数据
      canvas: null,
      downloadCanvasUrl: null,
      canvasWidth: 600,
      canvasHeight: 600,
      exportImgWidth: 2000,
      exportImgHeight: 2000,
      exportImgWidthDialog: 2000,
      exportImgHeightDialog: 2000,
      canvasSizeList:[
        {
        "title":"1:1 Square Chart",
        "width": 600,
        "height": 600
        },
        {
        "title":"3:4 Square Chart",
        "width": 450,
        "height": 600
        }
      ],
      canvasSizeIndex:0,
      productLink:null,
      dataList:[],
      excelDataList:[],
      productListingIndex:0,// 用来存储当前选中的index,默认第一个
      newCategory: "",    // 用于 el-input 的绑定
      showSmartMatch: false, // 控制 Smart Match 信息的显示
      pageloading: false,
      progress: 0,
      progressInterval:null,
      furnitureCategories:[],
      nofurnitureCategories: [],
      checkedCateList:[],
      checkedCateListNoFurniture:[],
      editlDialogImageVisible: false,
      generateImageDialogFlag: null,
      generateImageDialogData: null,
      submitCanvasDialogLoading: false,
      uploadedFile: null,
      uploadedImage: null, // 用户上传的图片
      uploadedImageUrl: null,//画布上传后链接
      previewImageUrl:null,//预览图片
      getExcelDataListLoading: false,
      dialogVisible: false,
      previewImgindex: 0,
      rateValue: 5,
      fetchContentDetailsLoading: false,
      keywords: null,
      guidance: '',
      savedialogVisible: false,
      editskuNumber: null,
      isshowDeleteIcon: false,
      selectedImage: 0,
      downloadMattingImageDisabled: true,
      prompt: '',
      generateImagePromptLoading: false,
      toggleSmartMatchLoading: false,
      addCategoryLoading: false,
      triggerFileInputLoading: false,
      decoraImgDialogVisible: false,
      decoraImgUrl:'',
      addImageItemValue: '',
      addImageItemLoading: false,
      downloadImageZipLoading: false,
      guidanceImageUrl:'',
      sizeDrawingVisible: false,
      sizeDrawingFile: {},
      sizeDrawingUrl: '',
      isAddInformationImages: false,
      addInformationImages:[],
      productInfoImageUrlList:[]
    }
  },
  computed: {
    currentImageUrl() {
      const listing = this.mainData?.productListingList?.[this.productListingIndex];
      return listing?.imageUrlList?.[this.selectedImage]?.jpegUrl || null; // 返回 URL 或 null
    },
    currentImageDialogUrl() {
      const listing = this.mainData?.productListingList?.[this.productListingIndex];
      return listing?.imageUrlList?.[this.previewImgindex]?.jpegUrl || null; // 返回 URL 或 null
    },
    currentImageUrlList(){
      const listing = this.mainData?.productListingList?.[this.productListingIndex]?.imageUrlList;
      return listing || [];
    },
    idaddCategoryBtnShow(){
      if(!this.newCategory){
        return true;
      }else{
        return false;
      }
    }
  },
  created(){
   this.queryInit();
  },
  mounted(){
    
  },
  methods:{
    handleSizeChange: function(val) {
      this.pageDomain.pageSize = val;
      this.getDataList();
    },
    handleSelectionChange: function(val) {
      this.multipleSelection = val;
    },
    handleCurrentChange: function() {
      this.getDataList();
    },
    queryInit: function() {
      var  _this= this;
      var dataMap = {
        query:_this.query
      };
      _this.$axios.get('/productPublish/xaz/selectById?id='+ _this.id).then((result) => {
        if(result.flag == true) {
          _this.mainData = result.productPublish;
          _this.oriMainData = _.cloneDeep(result.productPublish);
          const index = this.platformList.findIndex(option => option.value === _this.mainData.platform);
          _this.siteList = this.platformList[index].selectSiteList;
        }
      });
    },
    getVisibleCount(index) {
      // 计数器，初始化为0
      let count = 0;
      // 遍历整个 excelDataList
      for (let i = 0; i <= index; i++) {
        // 检查当前项的 display 属性
        if (this.mainData.excelDataList[i].display) {
          count++;
        }
      }
      return count;
    },
    chanagePlatform(value){
      //埋点
      var logstr = "history_platform_change_click?platform=("+value+")";
      this.addTrackingLog(logstr);
      const index = this.platformList.findIndex(option => option.value === value);
      this.siteList = this.platformList[index].selectSiteList;
    },
    chanageSite(){
      var _this = this;
      //埋点
      var logstr = "history_site_change_click?site=(" + _this.mainData.site +")";
      _this.addTrackingLog(logstr);
    },
    chanageBrand(){
      var _this = this;
      //埋点
      var logstr = "history_brand_change_click?brand=(" + _this.mainData.brandName +")";
      _this.addTrackingLog(logstr);
    },
    setActiveIndex(index) {
      //埋点
      var logstr = "history_category_change_click";
      this.addTrackingLog(logstr);

      this.productListingIndex = index; // 设置当前选中的index
    },
    async addCategory() {
      //埋点
      var logstr = "history_category_add_click?category=" + this.newCategory;
      this.addTrackingLog(logstr);
      if (this.newCategory.trim()) {
        this.addCategoryLoading = true;
        var newproductListing = {
          id: null,
          parentId: this.mainData.productListingList[0].parentId,
          categoryPath: this.newCategory, 
          categoryType: 1,
          title: null,
          description: null,
          fabricType: this.mainData.productListingList[0].fabricType,
          bulletPoints: [],
          imageUrlList: [],
          scenario: this.mainData.productListingList[0].scenario,
          excelDataList: this.mainData.productListingList[0].excelDataList,
          platform: this.mainData.productListingList[0].platform,
          site: this.mainData.productListingList[0].site,
          language: this.mainData.productListingList[0].language,
        }
        this.mainData.productListingList.push(newproductListing);//先塞入后调接口拿数据
        this.addCategoryLoading = false;
        this.fetchAddCatetoryContentDetails(this.newCategory,1);//调接口获取新的title和decsrition
        this.fetchAddCatetoryGenerateImage(this.newCategory);
        
      } else {
        this.$message.warning("Please enter a category!"); // 提示用户输入
      }
    },
    removeCategory(index) {
      //埋点
      var logstr = "history_category_delete_click";
      this.addTrackingLog(logstr);

      this.mainData.productListingList.splice(index, 1); // 根据传入的index删除相应的类别
      if (this.mainData.productListingList) {
        this.productListingIndex = 0; 
      }
    },
    toggleSmartMatch() {
      //埋点
      var logstr = "history_category_smart_match_click";
      this.addTrackingLog(logstr);
      this.generateCategories();
      this.showSmartMatch = true; // 切换显示 smart match 信息
    },
    closeSmartMatch(){
      //埋点
      var logstr = "history_category_smart_match_close_click";
      this.addTrackingLog(logstr);
      this.$nextTick(() => {
          this.showSmartMatch = false; // 设置状态为 false
      });
    },
    async generateCategories() {
      //this.pageloading = true;
      // this.progress = 0;
      // //每2秒进度条增加5
      // this.progressInterval = setInterval(async () => {
      //   this.progress += 5; // 增加进度
      //   if (this.progress >= 90) {
      //     this.progress = 90; // 防止超过100
      //   }
      // }, 3500);
      this.toggleSmartMatchLoading = true;
      try {
        // 发送 GET 请求
        var dataMp = {
            platform: this.mainData.platform,
            site: this.mainData.site,
            url: this.mainData.productImageUrl,
            furnitureCount: 10,
            notFurnitureCount: 0,
            excludedFurnitureCategories: this.furnitureCategories,
            excludedNotFurnitureCategories : this.nofurnitureCategories
        }
        const response = await this.$axios.post('/productPublish/xaz/generateCategories', dataMp);
        if (!response.flag) {
          this.$message.error(response.msg);
          return;
        }
        // 处理成功的响应
        if (response.categories) {
          this.furnitureCategories = response.categories[0]; 
          this.nofurnitureCategories = response.categories[1]; 
          // this.progress = 100;
          // clearInterval(this.progressInterval); // 停止定时器
          
        }
      } catch (error) {
        // 处理错误
        this.$message.error(error); 
      } finally {
        this.pageloading = false;
        this.toggleSmartMatchLoading = false;
      }
    },
    changeFurnitureCate() {
        //埋点
        var logstr = "history_category_smart_match_add_click";
        this.addTrackingLog(logstr);
        // 检查 checkedCateList 是否存在
        if (this.checkedCateList && Array.isArray(this.checkedCateList)) {
            for (let i = 0; i < this.checkedCateList.length; i++) {
                const categoryPath = this.checkedCateList[i];

                // 检查 categoryPath 是否已经存在
                const exists = this.mainData.productListingList.some(product => product.categoryPath === categoryPath);
                
                // 如果不存在，则添加新的产品
                if (!exists) {
                    const newProductListing = {
                        id: null,
                        parentId: this.mainData.productListingList[0].parentId,
                        categoryPath: categoryPath, 
                        categoryType: 1,
                        title: null,
                        description: null,
                        fabricType: this.mainData.productListingList[0].fabricType,
                        bulletPoints: [],
                        imageUrlList: [],
                        scenario: this.mainData.productListingList[0].scenario,
                        excelDataList: this.mainData.productListingList[0].excelDataList,
                        platform: this.mainData.productListingList[0].platform,
                        site: this.mainData.productListingList[0].site,
                        language: this.mainData.productListingList[0].language,
                    };
                    
                    // // 将新的产品添加到列表
                    this.mainData.productListingList.push(newProductListing);
                    this.fetchAddCatetoryContentDetails(categoryPath,1);
                    this.fetchAddCatetoryGenerateImage(categoryPath);
                }
            }
        }
        //this.checkedCateList = [];
    },
    changeNoFurnitureCate(){
      //埋点
      var logstr = "history_category_smart_match_add_click";
      this.addTrackingLog(logstr);
      // 检查 checkedCateListNoFurniture 是否存在
      if (this.checkedCateListNoFurniture && Array.isArray(this.checkedCateListNoFurniture)) {
          for (let i = 0; i < this.checkedCateListNoFurniture.length; i++) {
              const categoryPath = this.checkedCateListNoFurniture[i];

              // 检查 categoryPath 是否已经存在
              const exists = this.mainData.productListingList.some(product => product.categoryPath === categoryPath);

              // 如果不存在，则添加新的产品
              if (!exists) {
                  // const newProductListing = {
                  //     id: null,
                  //     parentId: this.mainData.productListingList[0].parentId,
                  //     categoryPath: categoryPath, 
                  //     categoryType: 2,
                  //     title: this.mainData.productListingList[0].title,
                  //     description: this.mainData.productListingList[0].description,
                  //     fabricType: this.mainData.productListingList[0].fabricType,
                  //     bulletPoints: this.mainData.productListingList[0].bulletPoints,
                  //     imageUrlList: this.mainData.productListingList[0].imageUrlList,
                  //     scenario: this.mainData.productListingList[0].scenario,
                  //     excelDataList: this.mainData.productListingList[0].excelDataList,
                  //     platform: this.mainData.productListingList[0].platform,
                  //     site: this.mainData.productListingList[0].site,
                  //     language: this.mainData.productListingList[0].language,
                  // };
                  
                  // // 将新的产品添加到列表
                  // this.mainData.productListingList.push(newProductListing);
                  this.fetchAddCatetoryContentDetails(categoryPath,2);
              }
          }
      }
      //this.checkedCateListNoFurniture = [];
    },
    async fetchAddCatetoryContentDetails(categoryPath,type){
      
      var checkedCateList = [];
      var checkedCateListNoFurniture = [];
      if(type == 1){
        checkedCateList.push(categoryPath);
      }else{
        checkedCateListNoFurniture.push(categoryPath);
      }
      
      var dataMap={
        "platform": this.mainData.platform,
        "site": this.mainData.site,
        "productImageUrl": this.mainData.productImageUrl,
        "furnitureCategories": checkedCateList,
        "notFurnitureCategories": checkedCateListNoFurniture,
        "brandName": this.mainData.brandName,
        "skuNumber": this.mainData.skuNumber,
        "keywords" : this.keywords
      }
      this.$axios.post('/productPublish/xaz/generateContent', dataMap).then(async result => {
        if (!result.flag) {
          this.$message.error(result.msg);
          this.addCategoryLoading = false;
          return;
        }

      
        // 查找 categoryPath 在 productListingList 的index
        const index = this.mainData.productListingList.findIndex(dataItem => dataItem.categoryPath === categoryPath);

        var newproductListing = {
          id: null,
          parentId: this.mainData.productListingList[0].parentId,
          categoryPath: result.resultList[0].categoryPath, 
          categoryType: 1,
          title: result.resultList[0].title,
          description: result.resultList[0].description,
          fabricType: this.mainData.productListingList[0].fabricType,
          bulletPoints: result.resultList[0].bulletPoints,
          imageUrlList:[this.mainData.productListingList[0].imageUrlList],
          scenario: this.mainData.productListingList[0].scenario,
          excelDataList: this.mainData.productListingList[0].excelDataList,
          platform: this.mainData.productListingList[0].platform,
          site: this.mainData.productListingList[0].site,
          language: this.mainData.productListingList[0].language,
        }
        // 如果 index 存在，则替换；否则，添加新项
        if (index !== -1) {
            // 如果存在，替换相应的元素
            this.mainData.productListingList[index] = newproductListing;
        } else {
            // 如果不存在，添加新的 productListing
            this.mainData.productListingList.push(newproductListing);
        }

        this.addCategoryLoading = false;
        this.newCategory = ""; // 添加后清空输入框
      })
      
      
      
    },
    async fetchAddCatetoryGenerateImage(categoryPath) {
        // 查找 categoryPath 在 productListingList 的index
        const index = this.mainData.productListingList.findIndex(dataItem => dataItem.categoryPath === categoryPath);
        const dataMap = {
            "category": categoryPath,
            "imageUrl": this.mainData.productImageUrl,
            "n": 1,
            "platform":this.mainData.platform
        };
        try {
            const result = await this.$axios.post('/productPublish/xaz/generateImage', dataMap);
            
            if (!result.flag) {
                this.$message.error(result.msg);
                return; // Early exit on error
            }
            this.mainData.productListingList[index].imageUrlList = result.imageUrlList;
            
        } catch (error) {
            console.error("Error generating image:", error);
            this.$message.error('Failed to generate image');
        }
    },
    setSelectedImage(index) {
      this.selectedImage = index;
    },
    removeImage(index) {
      //埋点
      var logstr = "history_image_delete_click";
      this.addTrackingLog(logstr);
      const product = this.mainData.productListingList[this.productListingIndex];
      if (product && product.imageUrlList) {
        product.imageUrlList.splice(index, 1); // 使用 splice 删除选中的图片
      }
    },
    showEditlDialogImageVisible(){
      //埋点
      var logstr = "history_image_add_click";
      this.addTrackingLog(logstr);
      this.generateImagePrompt();//跑一次拿prompt
      this.editlDialogImageVisible = true;
    },
    handleEditlDialogImageClose(){
      //埋点
      var logstr = "history_image_back_click";
      this.addTrackingLog(logstr);
      this.editlDialogImageVisible = false;
    },
    decoraImgage(index){
      const product = this.mainData.productListingList[this.productListingIndex];
      if (product && product.imageUrlList) {
        this.decoraImgUrl = product.imageUrlList[index].jpegUrl;
        console.log(this.decoraImgUrl)
      }
      this.decoraImgDialogVisible = true;
    },
    //埋点
    addTrackingLog(eid){
      this.$axios.get('/productPublish/xaz/addTrackingLog', {
        params: {
          e: eid,
        }
      });
    },
    showPreviewDialog(){
      //埋点
      var logstr = "history_preview_click";
      this.addTrackingLog(logstr);
      this.dialogVisible = true;
    },
    handlePreviewClose(){
      //埋点
      var logstr = "history_preview_back_click";
      this.addTrackingLog(logstr);
      this.dialogVisible = false;
    },
    showSaveChanages(){
      //埋点
      var logstr = "history_save_change_click";
      this.addTrackingLog(logstr);
      this.savedialogVisible = true;
    },
    handleSaveChanagesClose(){
      //埋点
      var logstr = "history_save_change_back_click";
      this.addTrackingLog(logstr);
      this.savedialogVisible = false;
    },
    //canvas 图片编辑
    initCanvasDialog() {
      if(this.canvas != null){
        this.canvas.clear(); // 清空画布上的所有对象
        this.canvas.dispose(); // 释放资源
      }
      this.$nextTick(() => {
        this.canvas = new fabric.Canvas('fabricCanvas', {
          targetFindTechnique: 'nearest',//创建Canvas时设置DPI
          perPixelTargetFind: true,// 创建Canvas时设置DPI
          enableRetinaScaling:false, // 或者 false，视具体情况而定
          width: this.canvasWidth,
          height: this.canvasHeight,
        });
        this.initImage(this.downloadCanvasUrl);
      })
      //this.initImage(this.mainData.productImageUrl);
    },
    initImage(dataUrl) {
      var _this = this;
      if (dataUrl) {
          fabric.FabricImage.fromURL(dataUrl).then((img) => {
            _this.canvas.add(img);

            //初始化原图缩放到画布大小的90%
            // 获取画布的宽度和高度
            var canvasWidth = _this.canvas.width;
            var canvasHeight = _this.canvas.height;

            // 计算缩放比例（90%）
            var scaleX = (canvasWidth * 0.9) / img.width;
            var scaleY = (canvasHeight * 0.9) / img.height;

            // 选择最小比例，以保持纵横比
            var scale = Math.min(scaleX, scaleY);
            // 缩放图像
            img.scale(scale);

            img.set({
              imageSmoothingEnabled: false,// 关闭图片插值，确保图片在拉宽时不会出现重影
              lockUniScaling: true, // 锁定宽高比，使其只能等比例缩放
              hasControls: true,    // 允许使用控制点
              hasBorders: true,
              left: (canvasWidth - img.width * scale) / 2,
              top: (canvasHeight - img.height * scale) / 2
            });
            // 监听缩放事件（可选，提升用户体验）
            _this.canvas.on('object:scaling', function(e) {
                var obj = e.target;
                obj.setControlsVisibility({
                    mt: false, // 不显示上中点
                    mb: false, // 不显示下中点
                    ml: false, // 不显示左中点
                    mr: false, // 不显示右中点
                    bl: true, // 显示左下点
                    br: true, // 显示右下点
                    tl: true, // 显示左上点
                    tr: true  // 显示右上点
                });
                // 同步 scaleX 和 scaleY 以保持宽高比
                obj.scaleY = obj.scaleX; 
            });
            _this.canvas.renderAll();
            
          }, { crossOrigin: 'anonymous' });// 处理跨域请求
      }

    },
    // 触发隐藏的文件选择输入
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChangeDialog(event) {
      // 获取用户选择的文件
      this.productLink = null ; //清空输入框链接
      this.triggerFileInputLoading = true;
      //埋点
      this.addTrackingLog("history_image_upload_click");
      // 获取用户选择的文件
      const file = event.target.files[0];
      this.uploadedFile = file;
      if (file) {
        if (file.type.startsWith('image/')) {
          const reader = new FileReader();
          // 读取文件
          reader.onload = (e) => {
            // 创建一个新的 Image 对象，检查尺寸
            const img = new Image();
            img.onload = () => {
              // 检查图像尺寸
              if (img.width > 5000 || img.height > 5000) {
                this.previewImageUrl = ''; // 清除预览
                this.$message.error("The uploaded image size cannot exceed 5000*5000. Please re-upload.");
              } else {
                // 设置预览图像的 URL
                this.previewImageUrl = e.target.result;
              }
            };
            img.src = e.target.result; // 设置 Image 对象的源为读取的文件
          };
          // 开始读取文件
          reader.readAsDataURL(file);
        } else {
          // 选择的不是图像文件，提示失败信息
          this.previewImageUrl = ''; // 清除预览
          this.uploadedFile = null;
          this.$message.error("请选择一个有效的图像文件。");
        }
      }

      //文件上传后渲染画布
      this.handleLinkSubmitDialog();
    },
    chanageProductLinkDialog(){
      this.previewImageUrl = this.productLink;
      this.uploadedFile = null; //清空选择文件
      //文件上传后渲染画布
      this.handleLinkSubmitDialog();
    },
    //第下载图片到画布
    async handleLinkSubmitDialog(){
      var _this = this;
      // _this.pageloading = true;
      // _this.progress = 0;
      // //每2秒进度条增加5
      // _this.progressInterval = setInterval(async () => {
      //   _this.progress += 5; // 增加进度
      //   if (_this.progress >= 90) {
      //     _this.progress = 90; // 防止超过100
      //   }
      // }, 3500);
      _this.handleLinkSubmitLoading = true;
      //输入框URL 模式
      if(_this.productLink != null && _this.productLink !=''){
          //先抠图
          const segmentResponse = await _this.segmentImage(_this.productLink);
          if (!segmentResponse.flag) {
            _this.$message.error(segmentResponse.msg);
            // _this.pageloading = false;
            // clearInterval(_this.progressInterval); // 停止定时器
            return;
          }

          //获取抠图后的png url 下载，渲染到画布
          var png = segmentResponse.imageUrl.pngUrl;
          if(png){
            const downloadResponse = await _this.downloadImageByUrl(png);
            if (downloadResponse.response.headers.flag ==='false' ) {
              const msg = await downloadResponse.text();
              this.$message.error(msg);
              return;
            }
            _this.downloadCanvasUrl = URL.createObjectURL(downloadResponse);
           // _this.mainData.productImageUrl = _this.downloadCanvasUrl // 替换掉原先抠图
            _this.downloadMattingImageDisabled = false;
            _this.$nextTick(() => {
              _this.initCanvasDialog();
              _this.handleLinkSubmitLoading = false;
              _this.triggerFileInputLoading = false;
            })
            
          }
          
          // _this.progress = 100;
          // _this.pageloading = false;
          // clearInterval(_this.progressInterval); // 停止定时器
      }else{//文件上传
          // 上传画布，获取图片链接
          if (_this.uploadedFile) {
              try {
                const uploadResponse = await _this.uploadImage();
                if (!uploadResponse.flag) {
                  _this.$message.error(uploadResponse.msg);
                  // _this.pageloading = false;
                  // clearInterval(_this.progressInterval); // 停止定时器
                  return;
                }
                _this.uploadedImageUrl = uploadResponse.url;
                _this.previewImageUrl = uploadResponse.url;
              } catch (error) {
                _this.$message.error(error);
                return;
              } 
          }
          //抠图
          const segmentResponse = await _this.segmentImage(_this.uploadedImageUrl);
          if (!segmentResponse.flag) {
            _this.$message.error(segmentResponse.msg);
            // _this.pageloading = false;
            // clearInterval(_this.progressInterval); // 停止定时器
            return;
          }

          //获取抠图后的png url 下载，渲染到画布
          var png = segmentResponse.imageUrl.pngUrl;
          if(png){
            const downloadResponse = await _this.downloadImageByUrl(png);
            if (downloadResponse.response.headers.flag ==='false' ) {
              const msg = await downloadResponse.text();
              this.$message.error(msg);
              return;
            }
            _this.downloadCanvasUrl = URL.createObjectURL(downloadResponse);
            //_this.mainData.productImageUrl = _this.downloadCanvasUrl // 替换掉原先抠图
            _this.downloadMattingImageDisabled = false;
            _this.$nextTick(() => {
              _this.initCanvasDialog();
              _this.handleLinkSubmitLoading = false;
              _this.triggerFileInputLoading = false;
            })
            
          }
          
          // _this.progress = 100;
          // _this.pageloading = false;
          // clearInterval(_this.progressInterval); // 停止定时器
      }
    },
    //抠图
    async segmentImage(productLink) {
      return this.$axios.get('/productPublish/xaz/segmentImage', {
        params: {
          url: productLink,
          platform: this.mainData.platform
        }
      });
    },
    //下载图
    async downloadImageByUrl(url) {
      return this.$axios.get('/productPublish/xaz/downloadByUrl', {
        params: {
          url: url,
        },
        responseType: 'blob' // 期望返回的类型是 Blob
      });
    },
    //上传图片
    async uploadImage() {
      var formData = new FormData();
      if(this.uploadedFile.raw){
        formData.append("file", this.uploadedFile.raw);
      }else{
        formData.append("file", this.uploadedFile);
      }
      formData.append("platform", this.mainData.platform);
      return this.$axios.post('/productPublish/xaz/uploadFile', formData);
    },
    chanageExportImgSizeDialog(){
      if(this.exportImgWidth === null ){
        this.$message.error("Please enter the width of the image"); 
        return;
      }
      if(this.exportImgHeight === null){
        this.$message.error("Please enter the height of the image"); 
        return;
      }

      if(this.exportImgWidth && this.exportImgWidth > 5000){
        this.$message.error("Exceeded the maximum image limit of 5000×5000"); 
        return;
      }
      if(this.exportImgHeight && this.exportImgHeight > 5000){
        this.$message.error("Exceeded the maximum image limit of 5000×5000"); 
        return;
      }
      
      //保持画布高度600不变，宽度按照输入图片的尺寸缩放
      if (typeof this.exportImgWidth === 'string' && this.exportImgWidth.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.exportImgWidth = parseInt(this.exportImgWidth, 10);
      }
      if (typeof this.exportImgHeight === 'string' && this.exportImgHeight.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.exportImgHeight = parseInt(this.exportImgHeight, 10);
      }
      if (typeof this.canvasWidth === 'string' && this.canvasWidth.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.canvasWidth = parseInt(this.canvasWidth, 10);
      }
      if (typeof this.canvasHeight === 'string' && this.canvasHeight.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.canvasHeight = parseInt(this.canvasHeight, 10);
      }
      if(this.exportImgWidth > this.exportImgHeight){//如果宽度大于高度，则画布以600宽度为基准算画布高度
        var scaleX = 600/this.exportImgWidth;
        var coutY = this.exportImgHeight * scaleX;
        this.canvasHeight = coutY;
        this.canvasWidth = 600;
        
      }else{//如果宽度小于高度，则画布以600高度为基准算画布宽度
        var scaleY = 600/this.exportImgHeight;
        var coutX = this.exportImgWidth * scaleY;
        this.canvasWidth = coutX;
        this.canvasHeight = 600;
      }
      
      this.chanageCanvasSizeDialog();
    },
    //画布尺寸改变
    chanageCanvasSizeDialog(val){
      // this.canvasWidth = this.canvasSizeList[val].width;
      // this.canvasHeight = this.canvasSizeList[val].height;
      // 更新 Fabric.js canvas 尺寸
      this.canvas.setWidth(this.canvasWidth);
      this.canvas.setHeight(this.canvasHeight);

      // 先清除画布
      if (this.canvas !== null) {
          this.canvas.clear(); // 清空画布上的所有对象
          this.canvas.dispose();
      }
      this.canvas = null;
      this.$nextTick(() => {
        this.initCanvasDialog();
        this.handleLinkSubmitLoading = false;
      })
    },
    async downloadMattingImage () {
      var _this = this;
      // 获取用户选择的文件
      //_this.submitCanvasDialogLoading = true;
      // 获取画布的 Data URL
      // 计算目标宽高的比例
      if (typeof this.exportImgWidth === 'string' && this.exportImgWidth.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.exportImgWidth = parseInt(this.exportImgWidth, 10);
      }
      if (typeof this.exportImgHeight === 'string' && this.exportImgHeight.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.exportImgHeight = parseInt(this.exportImgHeight, 10);
      }
      if (typeof this.canvasWidth === 'string' && this.canvasWidth.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.canvasWidth = parseInt(this.canvasWidth, 10);
      }
      if (typeof this.canvasHeight === 'string' && this.canvasHeight.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.canvasHeight = parseInt(this.canvasHeight, 10);
      }
      const canvasAspect = _this.canvasWidth / _this.canvasHeight;
      const outputAspect = _this.exportImgWidth / _this.exportImgHeight;
      let scale;
      if (outputAspect > canvasAspect) {
          // 如果输出宽高比更宽，按宽度缩放
          scale = _this.exportImgWidth / _this.canvasWidth;
      } else {
          // 如果输出宽高比更高，按高度缩放
          scale = _this.exportImgHeight / _this.canvasHeight;
      }
      //let backgroundColor = _this.canvas.backgroundColor;
      //_this.canvas.backgroundColor = 'rgba(255, 255, 255, 1)';
      const dataUrl = _this.canvas.toDataURL({
        // quality: 0.7,
        format: 'png',
        multiplier: scale // 设置图像质量导出
      });
      //_this.canvas.backgroundColor = backgroundColor;

      // 转换 Data URL 为 Blob
      const response = await fetch(dataUrl);
      if (!response.ok) throw new Error('Network response was not ok');
      const blob = await response.blob();

      DownloadUtils.download(blob, 'canvas_transparent_bg.png', { type: 'image/png' });


      let backgroundColor = _this.canvas.backgroundColor;
      _this.canvas.backgroundColor = 'rgba(255, 255, 255, 1)';
      const dataUrl2 = _this.canvas.toDataURL({
        // quality: 0.7,
        format: 'jpeg',
        multiplier: scale // 设置图像质量导出
      });
      _this.canvas.backgroundColor = backgroundColor;

      // 转换 Data URL 为 Blob
      const response2 = await fetch(dataUrl2);
      if (!response2.ok) throw new Error('Network response was not ok');
      const blob2 = await response2.blob();

      DownloadUtils.download(blob2, 'canvas_white_bg.jpg', { type: 'image/jpeg' });
    },
    //提交弹框画布，获取多角度图片
    async submitCanvasDialog(){
      var _this = this;
      // 获取用户选择的文件
      _this.submitCanvasDialogLoading = true;
      //埋点
      var logstr 
      if(_this.prompt){
        logstr = "history_image_genrate_click?withPrompt=Yes";
      }else{
        logstr = "history_image_genrate_click?withPrompt=None";
      }
      _this.addTrackingLog(logstr);

      // 获取画布的 Data URL
      // 计算目标宽高的比例
      if (typeof this.exportImgWidth === 'string' && this.exportImgWidth.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.exportImgWidth = parseInt(this.exportImgWidth, 10);
      }
      if (typeof this.exportImgHeight === 'string' && this.exportImgHeight.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.exportImgHeight = parseInt(this.exportImgHeight, 10);
      }
      if (typeof this.canvasWidth === 'string' && this.canvasWidth.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.canvasWidth = parseInt(this.canvasWidth, 10);
      }
      if (typeof this.canvasHeight === 'string' && this.canvasHeight.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.canvasHeight = parseInt(this.canvasHeight, 10);
      }
      const canvasAspect = _this.canvasWidth / _this.canvasHeight;
      const outputAspect = _this.exportImgWidth / _this.exportImgHeight;
      let scale;
      if (outputAspect > canvasAspect) {
          // 如果输出宽高比更宽，按宽度缩放
          scale = _this.exportImgWidth / _this.canvasWidth;
      } else {
          // 如果输出宽高比更高，按高度缩放
          scale = _this.exportImgHeight / _this.canvasHeight;
      }
      let backgroundColor = _this.canvas.backgroundColor;
      _this.canvas.backgroundColor = 'rgba(255, 255, 255, 1)';
      const dataUrl = _this.canvas.toDataURL({
        quality: 0.7,
        format: 'jpeg',
        multiplier: scale // 设置图像质量导出
      });
      _this.canvas.backgroundColor = backgroundColor;
      
      // 转换 Data URL 为 Blob
      const response = await fetch(dataUrl);
      if (!response.ok) throw new Error('Network response was not ok');
      const blob = await response.blob();
      // 创建 File 对象
      var file = new File([blob], 'canvas-image.png', { type: 'image/png' });
      _this.uploadedFile = file;
      _this.uploadedImage = URL.createObjectURL(file);
      _this.previewImageUrl = URL.createObjectURL(file);
      
      // 上传画布，获取图片链接
      if (_this.uploadedFile) {
          try {
            const uploadResponse = await _this.uploadImage();
            if (!uploadResponse.flag) {
              _this.$message.error(uploadResponse.msg);
              return;
            }
            _this.uploadedImageUrl = uploadResponse.url;
            _this.previewImageUrl = uploadResponse.url;
            //_this.$message.success("success");
          } catch (error) {
            _this.$message.error(error);
            return;
          } finally {
            
          }
      }
      
      
      await _this.generateImageDialog(_this.mainData.productListingList[_this.productListingIndex]);
      //_this.pageloading = false;
      //_this.editlDialogImageVisible = false;
      
    },
    async generateImageDialog(data) {
        // this.pageloading = true;
        // this.progress = 0;
        // //每2秒进度条增加5
        // this.progressInterval = setInterval(async () => {
        //   this.progress += 5; // 增加进度
        //   if (this.progress >= 90) {
        //     this.progress = 90; // 防止超过100
        //   }
        // }, 3500);
        if(!this.prompt){
            this.$message.error("Please input prompt or Prompt Optimizer");
            return; 
        }
        const dataMap = {
            "category": data.categoryPath,
            "imageUrl": this.uploadedImageUrl,
            "n": 1,
            "platform": this.mainData.platform,
            "prompt": this.prompt,
            "guidanceImageUrl": this.guidanceImageUrl
        };
        try {
            const result = await this.$axios.post('/productPublish/xaz/generateImageUsingPrompt', dataMap);
            this.count++;
            // console.log(" this.count", this.count);

            if (!result.flag) {
                this.$message.error(result.msg);
                this.progress = 100;
                // clearInterval(this.progressInterval); // 停止定时器
                // this.pageloading = false;
                return; 
            }
            if(data.imageUrlList == null) {
              data.imageUrlList = result.imageUrlList;
            }else {
              data.imageUrlList.unshift(result.imageUrlList[0]); // 在数组的第一位添加元素
              
            }
            this.submitCanvasDialogLoading = false;
            this.editlDialogImageVisible = false;
            this.$message.success("success");
            //this.progress = 100;
            // clearInterval(this.progressInterval); // 停止定时器
            // this.pageloading = false;
        } catch (error) {
            console.error("Error generating image:", error);
            this.$message.error('Failed to generate image');
        }
    },
    handleEditlDialogImageClose(){
      this.editlDialogImageVisible = false;
    },
    generateImagePrompt(){
      this.generateImagePromptLoading = true;
      var dataMap={
        "imageUrl": this.mainData.productImageUrl,
        "category": this.mainData.productListingList[this.productListingIndex].categoryPath,
        "guidance": this.guidance
      }
      this.$axios.post('/productPublish/xaz/generateImagePrompt', dataMap).then(result => {
        console.log(result)
        if (!result.flag) {
          this.$message.error(result.msg);
          this.generateImagePromptLoading = false;
          return;
        }
        this.prompt = result.imagePrompt;
        
        this.generateImagePromptLoading = false;
      }).finally(() =>{
          this.generateImagePromptLoading = false;
      });
    },
    // async generateImagePrompt() {
    //   this.generateImagePromptLoading = true;
    //   var dataMap={
    //     "imageUrl": this.mainData.productImageUrl,
    //     "category": this.mainData.productListingList[this.productListingIndex].categoryPath,
    //     "guidance": this.guidance
    //   }
    //   try {
    //     const response = await this.$axios.post('/productPublish/xaz/generateImagePrompt', dataMap);
    //     this.prompt = result.imagePrompt;
        
    //     this.generateImagePromptLoading = false;
    //   } catch (error) {
    //     this.$message.error(result.msg);
    //     this.generateImagePromptLoading = false;
    //   }
    // },
    getExcelDataList(){
        this.getExcelDataListLoading = true;
        
        var dataMap={
          "platform": this.mainData.platform,
          "site": this.mainData.site,
          "excelDataList": this.mainData.excelDataList,
          "skuNumber": this.editskuNumber
        }
        this.$axios.post('/productPublish/xaz/getExcelDataList', dataMap).then(async result => {
          
          if (!result.flag) {
            this.$message.error(result.msg);
            this.getExcelDataListLoading = false;
            var logStr = "history_attributes_fail_auto-fill_click";
            //埋点
            this.addTrackingLog(logStr);
            return;
          }
          this.mainData.excelDataList = result.excelDataList;
          this.getExcelDataListLoading = false;
          this.mainData.skuNumber = result.merchandise.anumber;
          var logStr = "history_attributes_auto-fill_click?sku=" + result.merchandise.anumber;
          //埋点
          this.addTrackingLog(logStr);
        })
    },
    handleAttrInput(index){
      // 当输入框内容被清空时，将 inputValue 设置为 originalValue
      if (this.mainData.excelDataList[index] == "") {
        this.mainData.excelDataList[index] = null; // 清空时设置为 null
      }
    },
    changePreviewImg(index) {
      this.previewImgindex = index; // 点击缩略图时更新预览图索引
    },
    async fetchContentDetails(){
      // if(this.checkedCateList.length ==0 && this.checkedCateListNoFurniture.length ==0){
      //   this.$message.error("Please select at least one category！");
      //   return;
      // }
      this.fetchContentDetailsLoading = true;

      //埋点
      var logstr 
      if(this.keywords){
        logstr = "history_content_genrate_click?withKeywords=Yes";
      }else{
        logstr = "history_content_genrate_click?withKeywords=None";
      }
      this.addTrackingLog(logstr);
      
      
      // this.pageloading = true;
      // this.progress = 0;
      // //每2秒进度条增加5
      // this.progressInterval = setInterval(async () => {
      //   this.progress += 5; // 增加进度
      //   if (this.progress >= 90) {
      //     this.progress = 90; // 防止超过100
      //   }
      // }, 5000);

      var checkedCateList = [];
      var checkedCateListNoFurniture = [];
      
      if(this.mainData.productListingList[this.productListingIndex].categoryType ==1){
        checkedCateList.push(this.mainData.productListingList[this.productListingIndex].categoryPath);
      }else{
        checkedCateListNoFurniture.push(this.mainData.productListingList[this.productListingIndex].categoryPath);
      }
      console.log(this.mainData);
      var dataMap={
        "platform": this.mainData.platform,
        "site": this.mainData.site,
        "productImageUrl": this.mainData.productImageUrl,
        "furnitureCategories": checkedCateList,
        "notFurnitureCategories": checkedCateListNoFurniture,
        "brandName": this.mainData.brandName,
        "skuNumber": this.mainData.skuNumber,
        "keywords" : this.keywords,
        "productInfoImageUrlList": this.productInfoImageUrlList
      }
      this.$axios.post('/productPublish/xaz/generateContent', dataMap).then(async result => {
        if (!result.flag) {
          this.$message.error(result.msg);
          this.fetchContentDetailsLoading = false;
          this.pageloading = false;
          return;
        }
        this.mainData.productListingList[this.productListingIndex].title = result.resultList[0].title;
        this.mainData.productListingList[this.productListingIndex].description = result.resultList[0].description;
        this.mainData.productListingList[this.productListingIndex].bulletPoints = result.resultList[0].bulletPoints;
        this.progress = 100;
        clearInterval(this.progressInterval); // 停止定时器
        this.pageloading = false;
        this.fetchContentDetailsLoading = false;
      })
      
      
    },
    saveChanages(){
      //埋点
      var logstr = "history_save_change_overwrite_click";
      this.addTrackingLog(logstr);
      this.$axios.post('/productPublish/xaz/updateProductPublish', this.mainData).then(async result => {
        if (!result.flag) {
          this.$message.error(result.msg);
          return;
        }else{
          this.mainData = result.productPublish;
          this.oriMainData = _.cloneDeep(result.productPublish);
          this.$message.success("Success");
        }
        
      })
      .finally(() =>{
        
      });
    },
    createChanages(){
      //埋点
      var logstr = "history_save_change_new_click";
      this.addTrackingLog(logstr);
      this.$axios.post('/productPublish/xaz/createProductPublish', this.mainData).then(async result => {
        if (!result.flag) {
          this.$message.error(result.msg);
          return;
        }else{
          this.mainData = result.productPublish;
          this.oriMainData = JSON.parse(JSON.stringify(result.productPublish));
          this.$message.success("Success");
        }
        
      })
      .finally(() =>{
        
      });
    },
    handleDialogClick(e) {
      // 如果点击的是背景层而不是内容，关闭对话框
      if (e.target === e.currentTarget) {
        this.savedialogVisible = false; // 点击背景层，关闭对话框
      }
    },
    addImageItem(){
      if(!this.addImageItemValue){
        this.$message.error('Please input text');
          return;
      }
      this.addImageItemLoading = true;
      var dataMap={
        "platform": this.mainData.platform,
        "imageUrl": this.decoraImgUrl,
        "text": this.addImageItemValue
      }
      this.$axios.post('/productPublish/xaz/addImageItem', dataMap).then(async result => {
        if (!result.flag) {
          this.$message.error(result.msg);
          this.addImageItemLoading = false;
          this.decoraImgDialogVisible = false;
          return;
        }else{
          this.mainData.productListingList[this.productListingIndex].imageUrlList.push(result.imageUrl);
          this.$message.success("Success");
        }
        this.addImageItemValue = '';
        this.addImageItemLoading = false;
        this.decoraImgDialogVisible = false;
      })
      .finally(() =>{

      });
        
    },
    //copy
    async copyTitleDecrition(){
      try {
        //获取当前类目
        const listing = this.mainData.productListingList[this.productListingIndex];
        var copystr = (
          `Title: ${listing.title}\n` +
          `Description: ${listing.description}\n` +
          `Bullet Points: \n` +
          listing.bulletPoints.map(point => `- ${point}`).join('\n')
        );

        // 使用 Clipboard API 直接复制
        await navigator.clipboard.writeText(copystr);
        this.showCopyConfirmation(); // 显示复制确认
      } catch (err) {
        console.error('复制失败:', err);
      }
    },
    showCopyConfirmation() {
      // 提示用户内容已复制
      alert('内容已复制到剪贴板！');
    },
    goBackHistoryList(){
      
      var _this = this;
      var flag = _this.areObjectsEqual(_this.mainData,_this.oriMainData);
      console.log("object flag",flag)
      if(!flag){
          _this.$confirm('Before you leave this page, please remember to save your information.', {
            confirmButtonText: 'Back',
            cancelButtonText: 'Leave',
            type: 'warning',
            // 下面的属性禁用关闭按钮和 ESC 键关闭
            closeOnPressEscape: false, // 禁用 ESC 键关闭
            closeOnClickModal: false,   // 禁用点击遮罩关闭
          }).then(() => {
            // _this.saveChanages();//保存数据后返回
            // _this.$router.push({ name: 'PublishHistoryList'});
          }).catch(() => {
            _this.$router.push({ name: 'PublishHistoryList', query: { ifEditReturn: true }});//不保存直接返回 
          });
      }else{
        _this.$router.push({ name: 'PublishHistoryList', query: { ifEditReturn: true }});//不保存直接返回 
      }
      
    },
    // areObjectsEqual(obj1, obj2) {
    //   return JSON.stringify(obj1) === JSON.stringify(obj2);
    // },
    // areObjectsEqual(obj1, obj2) {
    //   return _.isEqual(obj1, obj2);
    // },
    areObjectsEqual(obj1, obj2) {
      // 如果两个对象都是 null，则视为相等
      if (obj1 === null && obj2 === null) return true;

      // 如果其中一个为 null，而另一个不为 null，则不相等
      if (obj1 === null || obj2 === null) return false;

      // 如果两个值是相同的引用，直接认为相等
      if (obj1 === obj2) return true;

      // 获取对象的键
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      // 如果键的数量不同，则不相等
      if (keys1.length !== keys2.length) return false;

      for (let key of keys1) {
        // 确保两个对象都有该键
        if (!keys2.includes(key)) return false;

        const value1 = obj1[key];
        const value2 = obj2[key];

        // 判断数组和对象
        const areBothObjects = (val1, val2) => typeof val1 === 'object' && val1 !== null && typeof val2 === 'object' && val2 !== null;
        
        // 处理 null 和空字符串的比较
        if ((value1 === null && value2 === '') || (value1 === '' && value2 === null)) {
          continue; // 视为相等，继续检查其他字段
        }

        if (areBothObjects(value1, value2)) {
          // 如果两个都是对象或数组，递归比较
          if (!this.areObjectsEqual(value1, value2)) {
            return false;
          }
        } else {
          // 进行具体的值比较（对于其他类型如数字、布尔值）
          if (value1 !== value2) {
            return false;
          }
        }
      }

      // 如果通过了所有检查，则视为相等
      return true;
    },

    downloadImageZip(){
      var _this = this;
      _this.downloadImageZipLoading = true;
      const dataMap = {
            "imageUrlList": _this.mainData.productListingList[_this.productListingIndex].imageUrlList
      };
      _this.$axios.post('/productPublish/xaz/downloadImageZip', dataMap,{responseType:'blob'}).then(async result => {
        if (result.response.headers.flag ==='false' ) {
          const msg = await result.text();
          _this.$message.error(msg);
          _this.downloadImageZipLoading = false;
          return;
        }
        var date = new Date();
        var dateStr = _this.formatDateAll(date);
        var fileName = _this.mainData.platform + "_" + _this.mainData.site + "_" + dateStr + ".zip"
        DownloadUtils.download(result,fileName);
        _this.downloadImageZipLoading = false;
      }).catch(() =>{

      });
    },
    formatDateAll(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}${month}${day}_${hours}${minutes}${seconds}`;
    },    

    beforeUpload(file) {
      const isImage = file.type === "image/png" || file.type === "image/jpeg";
      const isLt3M = file.size / 1024 / 1024 < 3; // 限制文件大小小于3MB
      if (!isImage) {
        this.$message.error("仅支持 JPG/PNG 格式的图片");
        return false;
      }
      if (!isLt3M) {
        this.$message.error("图片大小不能超过 3MB");
        return false;
      }
      return true;
    },
    handleFileChange(file, fileList) {
      if (fileList.length > 0) {
        if (!this.beforeUpload(file.raw)) {
          this.$refs.imageUpload.clearFiles();
          return;
        }
        this.sizeDrawingFile = file;

        this.sizeDrawingUrl = URL.createObjectURL(file.raw);
      }
    },

    async handleAddInformationChange(file, fileList) {
      // 当文件上传成功时
      if (fileList.length > 0) {
        if (!this.beforeAddInformationUpload(file.raw)) {
          this.$refs.imageAddInformationUpload.clearFiles();
          return;
        }
        //this.addInformationImages.push(file);
        //上传图片返回url
        if(file){
            try {
              const uploadResponse = await this.uploadAddImage(file);
              if (!uploadResponse.flag) {
                this.$message.error(uploadResponse.msg);
                return;
              }
              this.productInfoImageUrlList.push(uploadResponse.url);
            } catch (error) {
              this.$message.error(error);
              
            }
        }
         
        
      }
    },
    //上传图片
    async uploadAddImage(file) {
      var formData = new FormData();
      if(file.raw){
        formData.append("file", file.raw);
      }else{
        formData.append("file", file);
      }
      formData.append("platform", this.mainData.platform);
      return this.$axios.post('/productPublish/xaz/uploadFile', formData);
    },
    returnAddInformationImgUrl(file){
      return  URL.createObjectURL(file.raw);
    },
    beforeAddInformationUpload(file) {
      // 文件上传前的处理，比如限制文件大小和类型
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2; // 限制文件小于 2MB
      if (!isJPG) {
        this.$message.error('Upload Images in JPG or PNG format!');
      }
      if (!isLt2M) {
        this.$message.error('Image size must be smaller than 2MB!');
      }
      return isJPG && isLt2M; // 返回 true 允许上传，返回 false 阻止上传
    },
    removeAddInformationImage(index) {
      // 移除指定索引的图片
      this.addInformationImages.splice(index, 1);
    },


  }
}
</script>


<style lang="less" scoped deep>
.loadingMaskNoBg{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(51, 51, 51, 0.5);

  .progress-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2002;
    opacity: 1;
    width: 200px; /* 调整圆形进度条的宽度 */
    height: 200px; /* 调整圆形进度条的高度 */

    .el-progress{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 200px;
      .el-progress-circle{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 200px;
      }
      // /deep/ .el-progress-circle__track {
      //   stroke: rgba(255, 255, 255, 0.303);
      // }

      /deep/ .el-progress__text {
        font-size: 20px !important;
        color: #FFF !important;
      }
    }
    
  }
}
.container{
  font-family: 'Sans-serif';
}
.container_self {
  max-width: 80%;
  margin: 20px auto;
  padding: 20px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #e3e4e5;
}

.left {
  display: flex;
  align-items: center;
}
.left-icon{
  display: inline-block;
  width: 35px;
  height: 35px;
  text-align: center;
  vertical-align: middle;
  font-size: 16px;
  padding-top: 8px;
  color: #000;
}
.left-icon:hover{
  background-color: #f3f4f6;
  border: 1px solid #e3e4e5;
  border-radius: 50%;
}

.left-title{
  display: inline-block;
  margin-left: 10px;
  font-size: 24px;
  color: #1f2937;
  font-weight: 700;
}

.right {
  display: flex;
  gap: 10px; /*按钮之间的间距*/
}
.search-bar {
    display: flex;               /* 使用 flexbox 布局 */
    justify-content: space-between; /* 在主轴上平均分配两端的元素 */
    align-items: center;        /* 垂直居中对齐 */
    padding: 20px;              /* 添加内边距 */
    background-color: #fff;  /* 背景颜色可以根据需求调整 */
    margin: 20px 0px;
    border-radius: 8px;
}

.baseWrapper{
  width: 100%;
  height: 150px;
  border: 1px solid #ebeef5;
  box-shadow: 0 0 10px 10px rgba(197, 195, 195, 0.2); /* 设置四周阴影 */
  border-radius: 8px;
  margin: 40px 0px;
}
.baseWrapper .base-sku{
    color: #6d717b;
    line-height: 40px;
    padding-left: 20px;
    font-size: 14px;
    margin-top: 10px;
    span{
      color: #374151;
      font-weight: bold;
    }
}
.baseWrapper .base-item{
  width: 30%; /* 设定宽度为三分之一 */
  display: inline-block;
  box-sizing: border-box; /* 包括内边距和边框在内的宽度计算 */
  padding: 10px 10px 10px 20px; /* 加一点间距 */
  font-size: 14px;
  color: #374151;
  font-weight: bold;
} 

.base-item .el-select,
.base-item .el-input {
    width: 100%; /* 让 el-select 和 el-input 占满 base-item 的宽度 */
    

    /deep/ .el-input__inner{
      color: #111111;
    }
}

.category-container {
  width: 100%;
  padding: 20px;
  margin: 40px 0px;
  //border: 1px solid #ebeef5;
  box-shadow: 0 0 10px 10px rgba(197, 195, 195, 0.2); /* 设置四周阴影 */
  border-radius: 8px;
}

.main-title {
  color: #303133;
  margin-bottom: 24px;
  border-left: 4px solid #902de8;
  padding-left: 5px;
}

.product-category{
  width: 100%;
  margin: 30px 0px;
  //border: 1px solid #ebeef5;
  box-shadow: 0 0 10px 10px rgba(197, 195, 195, 0.2); /* 设置四周阴影 */
  border-radius: 8px;
}
.cate-title{
  background-color: #F9FAFB;
  border-bottom: 1px solid #e3e4e5;
  padding: 20px;
}
.sub-title {
  color: #303133;
  margin-bottom: 24px;
  border-left: 4px solid #902de8;
  padding-left: 5px;
  margin-bottom: 8px;
}
.cate-main{
  padding: 20px;
}
.cate-tag{
  padding: 10px 0px;
}
.cate-tag .el-tag{
  margin: 10px 10px 0px 0px;
  background-color: #f3f5f7;
  border-color: #e3e4e5;
  border-radius: 20px;
  color: #111111;
  font-size: 14px;
  cursor: pointer;
}
/deep/  .cate-tag .el-tag .el-tag__close {
    color: #902de8;
}
/deep/  .cate-tag .el-tag .el-tag__close:hover {
    color: #ffffff;
    background-color: #902de8;
}
.category-selector {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 8px;
}
.category-selector .el-input{
  flex: 1;
  /deep/.el-input__inner{
    border-color: #D8B4FE;
  }
}

.category-dropdown {
  flex: 1;
}

.action-group {
  display: flex;
  gap: 8px;
}

.action-group .action-add-button ,.action-add-button:after,.is-disabled{
  // background-color: #f3e8fe !important;
  // border-color: #e6cefe !important;
  // color: #902de8 !important;
  background: #ffffff !important;
  border: 1px solid #E9D5FF !important;
  color: #7E22CE !important;
}
.action-group .action-add-button:hover{
  // background-color: #f3e8fe !important;
  // border-color: #e6cefe !important;
  // color: #902de8 !important;
  background: #FAF5FF !important;
  border: 1px solid #E9D5FF !important;
  color: #7E22CE !important;
}
.action-amsrt-btn, .action-amsrt-btn:after{
  // background-color: #f3e8fe !important;
  // border-color: #e6cefe !important;
  // color: #902de8 !important;
  background: #ffffff !important;
  border: 1px solid #E9D5FF !important;
  color: #7E22CE !important;

}
.action-amsrt-btn:hover,.action-amsrt-btn:after{
  // background-color: #f3e8fe !important;
  // border-color: #e6cefe !important;
  // color: #902de8 !important;
  background: #FAF5FF !important;
  border: 1px solid #E9D5FF !important;
  color: #7E22CE !important;

}
.smart-match {
  color: #409eff;
  padding: 10px 5px;
}
.active {
  background-color: #f3e8fe !important;
  border-color: #D8B4FE !important;
  color: #6B21A8 !important;
}
.smart-match-info {
  position: relative;
  min-height: 200px;
  overflow-y: auto;
  margin-top: 20px; /* 区别与其他元素的间隙 */
  padding: 10px;
  border: 1px solid #dcdfe6; /* 添加边框以增强可读性 */
  border-radius: 8px;
  background-color: #F3F4F6; /* 可选背景色 */
  
  /deep/  .el-checkbox{
    display: block;
    cursor: pointer;
  }
}
.convas-container {
  width: 100%;
  margin: 40px 0px;
  //border: 1px solid #ebeef5;
  box-shadow: 0 0 10px 10px rgba(197, 195, 195, 0.2); /* 设置四周阴影 */
  border-radius: 8px;
}
.convas-title{
  background-color: #F9FAFB;
  border-bottom: 1px solid #e3e4e5;
  padding: 20px;
  position: relative;
}
.sub-title-span {
  color: #303133;
  margin-bottom: 24px;
  border-left: 4px solid #1b5dea;
  padding-left: 5px;
  margin-bottom: 8px;
}
.convas-title-btn{
  float: right;
  width: 120px;
  margin-top: -33px;

}

.convas-main{
  display: flex;
  width: 95%;
  margin: 0px auto;
  padding: 20px;
}
.main-img{
  flex: 1; /* 宽度为70% */
  margin-right: 60px; /*canvas和右边的图片部分之间的间距*/
  .el-image{
      border-radius: 8px;
      img{
        border-radius: 8px;
      }
  }
  .placeholder-image{
    width: 100%;
    height: auto;
  }
   
}
.opre-img {
  flex: 0 0 25%; /* 宽度为30% */
  display: flex;
  overflow-y: auto;
  flex-direction: column; /* 纵向排列内部元素 */
  border: 1px solid #e3e4e5;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 10px;
}

.opre-img ul {
  display: flex;
  flex-wrap: wrap; /* 還可以换行布局 */
  justify-content: space-between;
  padding: 0; /* 清除默认内边距 */
  margin: 0; /* 清除默认外边距 */
  list-style-type: none; /* 去掉列表样式 */
}

.opre-img li {
  width: 49%; /* li宽度为opre-img的50% */
  aspect-ratio: 1/1;
  border-radius: 6px;
  position: relative; /* 为img设置位置 */
  overflow: hidden; /* 防止溢出 */
  margin-bottom: 10px;
}
.thumbnail-item.selected {
  border: 2px solid #3B82F6;
}

.thumbnail-item:hover:not(.selected) {
  border:1px solid #BFDBFE;
}

.thumbnail-item:hover .delete-icon {
  visibility: visible;
}
.thumbnail-item:hover .edit-icon {
  visibility: visible;
}
.opre-img li .el-image {
  border-radius: 6px;
}

.opre-img li .el-image:hover {
  
}
.opre-img li img{
  border-radius: 6px;
}

.opre-img .opre-img-size {
  margin-top: 20px; /* 输入区域的上边距 */
  .mini-input{
        width: 120px;
        margin: 10px 15px;
      }
}
.delete-icon{
  position: absolute;
  top: 4px; 
  right: 6px;
  z-index: 4;
  width: 25px;
  height: 25px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 50%;
  opacity: 0.9;
  visibility: hidden;
  cursor: pointer;
  .el-icon-delete{
    color: red;
    font-size: 16px;
    margin-top: 5px;
  }
}

.edit-icon{
  position: absolute;
  top: 4px; 
  right: 35px;
  z-index: 4;
  width: 25px;
  height: 25px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 50%;
  opacity: 0.9;
  visibility: hidden;
  cursor: pointer;
  .el-icon-edit-outline{
    color: #902de8;
    font-size: 16px;
    margin-top: 5px;
  }
}

.add-img-icon{
    width: 49%;
    height: auto; /* 高度自适应 */
    aspect-ratio: 1 / 1; /* 同样设置比例 */
    background-color: #f8faff;
    border: 2px dotted #d2e2f4;
    display: flex; /* 启用 flexbox 布局 */
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    margin-top: 3px;
    cursor: pointer;
   i{
    font-size: 30px;
    color: #56a4f7;
   } 
}
.add-img-icon:hover{
    width: 49%;
    height: auto; /* 高度自适应 */
    aspect-ratio: 1 / 1; /* 同样设置比例 */
    background-color: #EFF6FF;
    border: 2px dotted #60A5FA;
    display: flex; /* 启用 flexbox 布局 */
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    margin-top: 3px;
    cursor: pointer;
   i{
    font-size: 30px;
    color: #60A5FA;
   } 
}


.mini-input {
  width: calc(50% - 10px); /* 输入框宽度 */
  display: inline-block; /* 变为块级元素 */
  margin-right: 10px; /* 输入框之间的间距 */
}



.field-container {
    display: flex; /* 使用 Flexbox 布局 */
    justify-content: space-between; /* 在两端对齐，保持中间间距 */
    font-weight: 500 !important;
}

.field-content {
    width:48%; /* 允许 field-content 自由扩展 */
    /* 这里可以添加额外的样式，如高度、字体、颜色等 */
    box-shadow: 0 0 10px 10px rgba(197, 195, 195, 0.2); /* 设置四周阴影 */
    border-radius: 8px;
    
}
.el-textarea,.el-input{
  width: 100%;
}
.field-content::after{
  clear: both;
}
.field-content-title{
  background-color: #F9FAFB;
  border-bottom: 1px solid #e3e4e5;
  padding: 20px;
}
.field-content-title-span {
  color: #303133;
  margin-bottom: 24px;
  border-left: 4px solid #0fa043;
  padding-left: 5px;
  margin-bottom: 8px;
}
.field-content-main{
  padding: 20px;
}
.field-conten-item{
  width: 100%;
  .ori-data div{
    margin: 10px 0px ;
  }
  /deep/ .el-textarea__inner,.el-input__inner{
      font-family: "Sans-serif" !important;
      border-color: #d1d5db;
  }

  /deep/ .el-textarea__inner:focus ,.el-input__inner:focus{
      border-color: #10B981 !important;
  }
  .bullet-list {
    padding-left: 10px; /* 给列表项增加左边距 */
    margin-left: 15px;
  }

  .bullet-list-li {
    text-align: left;
    list-style: disc; /* 使用实心点符号 */
    // margin-bottom: 5px; /* 每个列表项之间的间距 */
    line-height: 25px;
    width: 100%;
  }

  .bullet-list-li::marker {
    //color: #409eff; /* 单独控制实心点的颜色 */
    font-size: 16px; /* 可以调整实心点的大小 */
  }
}
.field-Keywords{
  padding: 15px 0px;
  border-top: 2px solid #e3e4e5;
  font-weight: bold;
}

/deep/.field-Keywords .el-input__inner:focus{
      border-color: #10B981 !important;
}
.field-btn-wrapper{
  text-align: center;
  margin: 20px 0px;
}
.field-content-btn{
  width: 100%;
  background-color: #ffffff !important; /* 按钮背景颜色 */
  border: 1px solid #BBF7D0 !important;
  color: #15803D !important; /* 按钮文字颜色 */
  font-weight: bold;
}

.field-content-btn:hover{
  width: 100%;
  background-color: #F0FDF4 !important; /* 按钮背景颜色 */
  border: 1px solid #BBF7D0 !important;
  color: #15803D !important; /* 按钮文字颜色 */
  font-weight: bold;
}
.field-attr {
    width:48%; /* 允许 field-content 自由扩展 */
    /* 这里可以添加额外的样式，如高度、字体、颜色等 */
    box-shadow: 0 0 10px 10px rgba(197, 195, 195, 0.2); /* 设置四周阴影 */
    border-radius: 8px;
    
    .title{
      color: #303133;
      margin-bottom: 24px;
      border-left: 4px solid #d97504;
      padding-left: 5px;
    }
}
.field-attr::after{
  clear: both;
}
.field-attr-title{
  background-color: #F9FAFB;
  border-bottom: 1px solid #e3e4e5;
  padding: 20px;
}
.field-attr-span {
  color: #303133;
  margin-bottom: 24px;
  border-left: 4px solid #d97504;
  padding-left: 5px;
  margin-bottom: 8px;
}

.field-attr-main{
  padding: 20px;
}
.infoma-right-top{
  // height: 52px;
  margin-top: 12px;
  .el-textarea,.el-input{
    width: 40%;
  }
  
  .enh-btn{
    width: 100px;
    background-color: #ffffff; /* 按钮背景颜色 */
    border: 1px solid #FDE68A;
    color: #B45309; /* 按钮文字颜色 */
    height: 30px; /* 按钮高度 */
    margin-top: 10px;
    margin-right: 10px; /* 按钮之间的间距 */
    text-align: center;
  }
  /deep/ .enh-btn:hover{
      background-color: #fffbeb !important; /* 按钮背景颜色 */
      border: 1px solid #FDE68A !important;
      color: #B45309 !important; /* 按钮文字颜色 */
  }

}
.infoma-right-ul{
  margin-top: 20px;
  height: 800px;
  background-color: #F9FAFB;
  padding-top: 10px;
  padding-left: 10px;
  overflow-y: auto;

 
  .infoma-right-ul-li{
    widows: 100%;
    // height: 40px;
    margin-top: 5px;
    div{
      // padding: 0px 5px;
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      vertical-align: middle;
    }
    .infoma-right-ul-li-no{
      width: 30px;
      text-align: left;
    }
    .infoma-right-ul-li-name{
      width: 56%;
      text-align: left;
    }
    .infoma-right-ul-li-value{
      // width: 39%;
      text-align: left;
    }
    .infoma-right-ul-li-input{
      text-align: right;
    }
  }
}

 /deep/.field-attr-main .el-input__inner, .field-attr-main .el-textarea__inner{
      font-family: "Sans-serif" !important;
      border-color: #d1d5db;
      font-weight: 500;
  }
  /deep/.field-attr-main .el-textarea__inner{
      font-family: "Sans-serif" !important;
      border-color: #d1d5db;
      font-weight: 500;
  }

  /deep/.field-attr-main .el-input__inner:focus{
      border-color: #F59E0B !important;
  }


.canvas-wrapper{
    display: flex;
    font-family: 'Sans-serif';
    .canvasSizeWrapper{
      width: 40%;
      text-align: center;
      padding-right: 30px;

      .canvas-upload-title{
        color: #1E40AF;
        font-weight: 500;
        border-bottom: 1px solid #DBEAFE;
        padding-bottom: 10px;
        margin-bottom: 15px;
        text-align: left;
        i{
          color: #2563EB;
        }
      }
      .canvas-upload-top{
        display: flex;
        justify-content: space-between;
        .canvas-chanage-size{
          width: 48%;
          border: 1px solid #DBEAFE;
          box-shadow: 0 0 1px 1px #DBEAFE; /* 设置四周阴影 */
          border-radius: 8px;
          padding: 12px;
          .canvas-chanage-size-item{
            display: flex;
            span{
              line-height: 30px;
              color: #457aee;
            }
            /deep/ .el-input{
              flex: 1;
              height: 30px;
              .el-input__inner{
                height: 30px;
                background-color: #ffffff;
                border-color: #DBEAFE;
              }
            }
          }
          .canvas-chanage-size-item:last-child{
            margin-top: 12px;
          }
        }
        .canvas-chanage-donload{
          width: 48%;
          border: 1px solid #DBEAFE;
          box-shadow: 0 0 1px 1px #DBEAFE; /* 设置四周阴影 */
          border-radius: 8px;
          padding: 12px;
          .canvas-chanage-donload-title{
            color: #1E40AF;
            font-weight: 500;
            padding-bottom: 10px;
            margin-bottom: 10px;
            text-align: left;
            i{
              color: #2563EB;
            }
          }
          .canvas-chanage-donload-btn{
            background: linear-gradient(to right, #2563EB, #4F46E5);
            color: #ffffff;
            width: 95%;
            margin: 0px auto;
            height: 35px;
            line-height: 35px;
          }
          .canvas-chanage-donload-btn:hover{
            background: linear-gradient(to right, #1D4ED8, #4338CA);
            color: #ffffff;
          }
        }
      }
      
      .convas-prompt{
        border: 1px solid #DBEAFE;
        box-shadow: 0 0 1px 1px #DBEAFE; /* 设置四周阴影 */
        border-radius: 8px;
        padding: 12px;
        margin-top: 28px;
        /deep/.el-textarea{
        width: 100%;
          .el-textarea__inner{
            background-color: #ffffff;
            border-color: #DBEAFE;
          }
        }
        .generate-btnWrapper{
          margin-top: 20px;
          // display: flex;
          // justify-content: space-between;
          text-align: right;
          .generate-opt-btn{
            background-color: #ffffff;
            border: 1px solid #BFDBFE;
            color: #1D4ED8;
            height: 35px;
            line-height: 35px;
            padding: 0px 20px;
          }
          .generate-opt-btn:hover{
            background-color: #EFF6FF;
            border: 1px solid #BFDBFE;
            color: #1D4ED8;
          }
          .generate-gen-btn{
            background: linear-gradient(to right, #2563EB, #4F46E5);
            color: #ffffff;
            height: 35px;
            line-height: 35px;
            padding: 0px 20px;
          }
          .generate-gen-btn:hover{
            background: linear-gradient(to right, #1D4ED8, #4338CA);
            color: #ffffff;
          }
        }

        .el-textarea-inner,.el-textarea-inner:focus{
          width: 100%;
          font-family: 'Sans-serif';
          background-color: #ffffff;
          border-color: #DBEAFE;
          border-radius: 4px;
          outline: none; /* 移除默认的焦点外边框 */
          padding: 5px;
      }
      }
      
    }
    .canvasMain{
      flex: 1;
      margin-right: 30px;
      text-align: center;
      
      .canvasDiv {
        border:1px solid #DBEAFE;
        border-radius: 8px;
        box-shadow: 0 0 2px 2px #DBEAFE; /* 设置四周阴影 */
        background: url(../assets/22.jpg) repeat;
      }
      .canvas-upload{
        width: 600px;
        margin: 0px auto;
        border: 1px solid #DBEAFE;
        border-radius: 8px;
        padding: 12px;
        margin-top: 20px;

        .canvas-upload-title{
          color: #1E40AF;
          font-weight: 500;
          border-bottom: 1px solid #DBEAFE;
          padding-bottom: 10px;
          margin-bottom: 10px;
          text-align: left;
          i{
            color: #2563EB;
          }
        }
        .file-and-link{
          display: flex;
          .upload-button {
            background-color: #2463eb; /* 按钮背景色 */
            color: white; /* 按钮文本颜色 */
            border: none; /* 去掉边框 */
            padding: 0px 20px; /* 内边距 */
            height: 35px;
            border-radius: 4px; /* 圆角 */
            cursor: pointer; /* 控制鼠标样式 */
            transition: background-color 0.3s; /* 背景色渐变效果 */
            width: 14%;
          }

          /* 鼠标悬浮效果 */
          .upload-button:hover {
            background-color: #66b1ff; /* 悬停时的颜色 */
          }
          .upload-span{
            width: 17px;
            display: inline-block;
            line-height: 35px;
            font-weight: bold;
            margin: 0px 8px;
          }
          .uploadFile {
            display: none; /* 隐藏原始文件输入 */
          }
          /deep/ .link-input{
            flex: 1;
            height: 35px;
            .el-input__inner{
              height: 35px;
              background-color: #ffffff;
              border-color: #DBEAFE;
            }
          }
        }
      }
    }
    .next-btn{
      float: right;
      width: 80px;
      background-color: black; /* 按钮背景颜色 */
      color: white; /* 按钮文字颜色 */
      height: 35px; /* 按钮高度 */
      margin: 10px 0; /* 按钮之间的间距 */
      text-align: center;
    }

    .back-btn{
      float: left;
      width: 80px;
      background-color: black; /* 按钮背景颜色 */
      color: white; /* 按钮文字颜色 */
      height: 35px; /* 按钮高度 */
      margin: 10px 0; /* 按钮之间的间距 */
      text-align: center;
    }
    /deep/ .back-btn:hover{
        background: #7f7f7f !important; /* 按钮背景颜色 */
        color: white; /* 按钮文字颜色 */
    }
}



.preview-wrapper{
  width: 90%;
  margin: 0px auto;
}
.preview-content {
  display: flex; /* 使用 flexbox 布局 */
  justify-content: space-between;
}

.preview-img {
  width: 55%; /* 左侧图片区域宽度 */
  display: flex; /* 使用 flexbox 布局到竖直方向 */
  flex-direction: column;
}

.preview-max-img {
  width: 100%; /* 图片填满预览区域的宽度 */
  height: auto; /* 高度自适应 */
}
.preview-placeholder {
  width: 100%; /* 占位符宽度 */
  height: 600px; /* 设置固定高度以保持布局 */
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}

.preview-img ul {
  display: flex; /* 横向排列缩略图 */
  flex-wrap: wrap; /* 换行显示 */
  padding: 0; /* 去掉内边距 */
  margin: 0; /* 去掉外边距 */
}

.preview-img li {
  flex: 0 0 auto; /* 固定宽高 */
  list-style: none; /* 去掉列表样式 */
  margin: 4px; /* 添加间距 */
  cursor: pointer; /* 鼠标指针为手型 */
}

.preview-mini-img {
  width: 80px; /* 设置缩略图宽度 */
  height: 80px; /* 设置缩略图高度 */
}

.preview-attr {
  width: 38%; /* 右侧属性区域宽度 */
  display: flex;
  flex-direction: column;
}
.preview-cate{
  padding: 15px 0px;
  font-size: 18px;
  font-weight: bold;
}

.preview-rate{
  padding: 15px 0px;
  .el-rate{
    display: inline-block;
    width: 150px;
  }
}
.preview-price{
  padding: 20px 0px;
  border-bottom: 1px solid #e3e4e5;
  .price-span{
    font-size: 30px;
    font-weight: bold;
  }
}
.preview-bullet{
  padding: 20px 0px;
  font-size: 18px;
  font-weight: bold;
  ul{
    display: block;
    li{
      line-height: 25px;
      list-style: disc;
      font-size: 14px;
      font-weight: 500;
    }
  }
  
}
.preview-desc {
  clear: both; /* 清除浮动 */
  padding: 20px 0px;
  font-size: 18px;
  font-weight: bold;
  border-top: 1px solid #e3e4e5;
  .preview-desc-span{
    line-height: 25px;
    word-wrap: break-word;
    font-size: 14px;
    font-weight: 500;
  }
}

.savedialog-wrapper{
  width: 40% !important;
  margin: 0px auto;
  /deep/.el-dialog {
      width: 100% !important;
  }
}
.save-dialog-div{
  display: flex;
  border: 1px solid #e3e4e5; 
  padding: 10px; 
  margin: 20px 50px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
}
.preview-btn,.preview-btn:hover{
  background-color: #ffffff;
  border: 1px solid #1f49cc;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #1f49cc;
}

.save-btn,.save-btn:hover{
  background-color: #2363eb;
  border: 1px solid #2363eb;
  height: 40px;
  line-height: 40px;
  color: #ffffff;
}

.transition-box {
    margin-bottom: 10px;
    width: 200px;
    height: 100px;
    border-radius: 4px;
    background-color: #409EFF;
    text-align: center;
    color: #fff;
    padding: 40px 20px;
    box-sizing: border-box;
    margin-right: 20px;
  }

.decora-img {
  width: 55%; /* 左侧图片区域宽度 */
  margin: 20px auto;
  display: flex; /* 使用 flexbox 布局到竖直方向 */
  flex-direction: column;
}

.decora-max-img {
  width: 100%; /* 图片填满预览区域的宽度 */
  height: auto; /* 高度自适应 */
}
.decora-opre{
  display: flex;

  .el-input{
    flex: 1;
    margin-right: 30px;
  }
}

.sizeDrawingWrapper{
  display: flex;
  justify-content: space-between;
}
.sizeDrawing-left{
  flex: 1;
  margin-right: 20px;
  padding: 15px;
}
.upload-section{
  width: 100%;
  margin-top: 20px;
}
.upload-img-main{
  width: 100%;
  height: 340px;
  border: 1px dotted #e3e4e5;
  border-radius: 8px;
}
.upload-demo{
  text-align: center;
  padding: 40px 20px 20px 20px;
}
.upload-img-show{
  width: 100%;
  height: 340px;
  text-align: center;
  border: 1px dotted #e3e4e5;
  border-radius: 8px;
  padding: 20px 0px;

}
.upload-img-show img{
    height: 300px;
    width: auto;
    object-fit: scale-down;
}
.sizeDrawing-left-input{
  margin: 20px 0px;
}
/deep/.sizeDrawing-left-input .el-input__inner{
  height: 40px;
  line-height: 40px;
}
/deep/.sizeDrawing-left-btn .el-button{
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #000;
  color: #fff;
  border-bottom: 8px;
}
/deep/.sizeDrawing-left-btn .is-disabled{
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #666 !important;
  color: #fff !important;
  border-bottom: 8px;
}
/deep/.sizeDrawing-left-btn .is-disabled:hover{
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #666 !important;
  color: #fff !important;
  border-bottom: 8px;
}
.upload-tips {
  background-color: #ffffff;
  border: 1px solid #e3e4e5;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 20px;
}

.upload-tips h3 {
  margin-top: 0;
}

.upload-tips ul {
  padding-left: 1.5rem;
  margin-top: 10px;
}

.upload-tips li {
  margin-bottom: 0.5rem;
  list-style: disc;
}

.sizeDrawing-right{
  width: 40%;
  padding: 15px;
}
.right-noresult{
  border: 1px solid #e3e4e5;
  border-radius: 8px;
  text-align: center; 
  padding: 20%;
  margin-top: 20px;
}
.right-noresult-icon{
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #f3f4f6;
  border: 1px solid #e3e4e5;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  font-size: 16px;
  padding-top: 8px;
  color: #000;
}

.right-result{
  margin-top: 20px;
}
.right-result li{
  border-bottom: 1px solid #e3e4e5;
  line-height: 40px;
}
.right-result li .result-parameter{
  display: inline-block;
  width: 40%;
  text-align: center;
}
.right-result li .result-value{
  display: inline-block;
  width: 30%;
  text-align: center;
}
.right-result li .result-unit{
  display: inline-block;
  width: 30%;
  text-align: center;
}
.sizeDrawing-footer{
  text-align: right;
}
.sizeDrawing-footer .canel-btn{
  width: 120px;
  height: 40px;
  line-height: 40px;
  background-color: #fff;
  color: #111;
  border: 1px solid #e3e4e5;
}
.sizeDrawing-footer .apply-btn{
  height: 40px;
  line-height: 40px;
  background-color: #000;
  color: #fff;
  border-bottom: 8px;
}
.sizeDrawing-footer .apply-btn:hover{
  height: 40px;
  line-height: 40px;
  background-color: #000;
  color: #fff;
  border-bottom: 8px;
}
/deep/.sizeDrawing-footer .is-disabled{
  height: 40px;
  line-height: 40px;
  background-color: #666 !important;
  color: #fff !important;
  border-bottom: 8px;
}
/deep/.sizeDrawing-footer .is-disabled:hover{
  height: 40px;
  line-height: 40px;
  background-color: #666 !important;
  color: #fff !important;
  border-bottom: 8px;
}


.image-upload-container {
  margin: 20px;
}

.image-preview {
  display: flex;
  flex-wrap: wrap; /* 让图片可以换行处理 */
  gap: 10px; /* 图片之间留出一些空间 */
}

.image-item {
  position: relative;
  width: 100px;
  height: auto;
}

.image-item img {
  width: 100%; /* 图片宽度，适当调整 */
  height: 100%; /* 图片高度，适当调整 */
  object-fit: cover; /* 保持图片的比例 */
}

.image-preview .el-upload {
  width: 100px; /* 设置上传按钮的宽度 */
  height: 100px; /* 设置上传按钮的高度 */
  display: inline-block;
  border: 2px dashed #409EFF; /* 边框样式 */
}

.image-preview .upload-tips {
  margin-top: 20px;
}

.image-preview .upload-tips ul {
  list-style-type: none;
  padding: 0;
}

.image-upload-num{
  text-align: right;
  padding: 10px 0px;
  font-size: 14px;
  font-weight: 500;
  color: #abb3b9;
}
.image-upload-tips{
  padding: 10px 0px;
  font-size: 14px;
  font-weight: 500;
  color: #abb3b9;
  font-style: italic; /* 斜体 */
}
</style>